import { Language } from './salesforce-types';

// Defining the labels structure so when a label is missing
// in other language, it will be asked as required by Typescript.
const ENGLISH_LABELS = {
  footerSlogan: 'NIH … Turning Discovery Into Health ®',
  diseases: 'Browse by Disease',
  contact: 'Contact Us',

  // Sections
  home: 'Home',
  disease: 'Disease',
  about: 'About GARD',
  privacyPolicy: 'Privacy Policy',
  vulnerabilityPolicy: 'Vulnerability Disclosure Policy',
  oig: 'OIG',
  foia: 'FOIA',
  accessibility: 'Accessibility',
  disclaimer: 'Disclaimer',
};

export type LanguageLabelKeys = keyof typeof ENGLISH_LABELS;

export type LanguageLabel = {
  [K in Language]: typeof ENGLISH_LABELS;
};

export const LABELS: LanguageLabel = {
  [Language.En]: ENGLISH_LABELS,

  [Language.Es]: {
    footerSlogan: 'NIH … Transformación de Descubrimientos en Salud ®',
    diseases: 'Enfermedades (En desarrollo)',
    contact: 'Contáctenos',

    home: 'Inicio',
    disease: 'Disease',
    about: 'Sobre GARD',
    privacyPolicy: 'Política de privacidad',
    vulnerabilityPolicy: 'Política de divulgación de vulnerabilidades',
    oig: 'OIG (en inglés)',
    foia: 'FOIA',
    accessibility: 'Accesibilidad',
    disclaimer: 'Aviso Legal',
  },
};
